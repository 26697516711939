<template lang="pug">
  #start-kit-checklist.step-component
    h1
      icon#rocket(data="@icon/rocketship.svg" :fill="false")
      | Now let's start the Kit!
    .form-field.checkbox(v-for="(item, key) in items" :key="key" v-if="(key != 'understandRecording' && proceededWithoutVideo) || !proceededWithoutVideo")
      input.on-dark(type="checkbox" v-model="itemStatus[key]" :value="itemStatus[key]" :id="key" :name="key")
      label(:for="key") {{ item }}

    button.button(@click="gotoNext" :disabled="!checklistComplete") I'm ready
</template>

<script>
// ***** Sample step config *****
// {
//   component: 'StartKitChecklist',
//   config: {
//     items: {
//       minutesFree: 'I have 35 minutes free now to complete the Kit',
//       drankWater: 'I drank 2 glasses of water',
//       understandRecording: 'I understand that my actions will be video-recorded',
//     },
//   },
// },
import { mapGetters } from 'vuex'

export default {
  props: {
    items: { type: Object, required: true },
  },

  data() {
    return {
      itemStatus: Object.keys(this.items).reduce(
        (obj, key) => Object.assign(obj, { [key]: false }),
        {}
      ),
    }
  },

  computed: {
    ...mapGetters({
      proceededWithoutVideo: 'user/getVideoPermission'
    }),
    checklistComplete,
  },

  watch: {},

  methods: {
    gotoNext,
  },
  
  mounted() {
    this.$store.commit('user/SET_VIDEO_PERMISSION', true)
  },
  
  components: {},
}

/* Computed ---------------------------------------------------- */
function checklistComplete() {
  if (this.proceededWithoutVideo) {
    this.itemStatus.understandRecording = true
  }
  return Object.values(this.itemStatus).indexOf(false) == -1
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function gotoNext() {
  this.$emit('complete')
}
</script>
